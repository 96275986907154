@import "~react-image-gallery/styles/css/image-gallery.css";

.img-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.section-four-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.025);
}

.content {
    position: absolute;
    inset: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.75) 100%);
    padding: 30px;
}

.img-wrapper>img,
.img-wrapper>.content {
    transition: 200ms all ease-in-out;
}

.img-wrapper>.content.fade {
    opacity: 0;
}

.img-wrapper:hover>.content.fade {
    opacity: 1;
}

.img-wrapper>.content.fade.active {
    opacity: 1;
}

.img-wrapper:hover>img.zoom {
    transform: scale(1.1);
}

/* SECTION FIVE */
.section-five-img-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.section-five-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 'center';
}

.section-five-filter {
    position: absolute;
    inset: 0;
    color: #2C2C2C;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    /* background: linear-gradient(180deg, #FFFFFF 8.5%, rgba(255, 255, 255, 0) 40%); */
    /* background: linear-gradient(270deg, #FFFFFF 26.9%, rgba(255, 255, 255, 0) 100%); */
}

.section-five-filter-mobile {
    position: absolute;
    inset: 0;
    color: #2C2C2C;
    background: linear-gradient(0deg, #f9f9f9 40.94%, rgba(255, 255, 255, 0) 86.18%);
}

.section-five-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

.section-five-content-mobile {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
}