/* .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
} */

.slide__portfolio.swiper-slide {
    text-align: center;
    font-size: 18px;
    height: calc((100% - 40px) / 2) !important;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}