.case_study_gallery .image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1);
    border: none;
    background: #fff;
}

.case_study_gallery .image-gallery-bullets .image-gallery-bullet {
    background-color: #959595;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: all .2s ease-out;
}

.case_study_gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    display: flex;
    width: 100%;
    background: transparent;
    /* max-width: 134px; */
}

.case_study_gallery .image-gallery-thumbnail {
    display: inline-block;
    transition: border .3s ease-out;
    background: transparent;
    padding: 0;
    width: calc(100% / 4);
    /* display: flex;
    flex-grow: 1; */
    /* width: fit-content; */
    /* max-width: 80px; */
    /* flex-grow: 1; */

}
.case_study_gallery .image-gallery-thumbnail .image-gallery-thumbnail-inner img {
    object-fit: cover;
}

.case_study_gallery .image-gallery-thumbnail:focus {
    border: 4px solid #144681;
}

.case_study_gallery .image-gallery-thumbnail:hover {
    border: 4px solid #144681;
}

.case_study_gallery .image-gallery-thumbnail:active {
    border: 4px solid #144681;
}

@media only screen and (max-width: 600px) {
    .case_study_gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
        max-width: 100%;
    }
}