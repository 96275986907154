/* SECTION FIVE */
.success-dialog-img-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.success-dialog-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 'center';
}

.success-dialog-filter {
    position: absolute;
    inset: 0;
    color: #2C2C2C;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(66, 163, 227, 0) 0%, rgba(66, 163, 227, 0.2) 38.02%, rgba(66, 163, 227, 0.7) 65.62%);
}

.success-dialog-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}