/* width */
::-webkit-scrollbar {
    width: 10px;
    /* display: none; */
    border-radius: 12px

  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #DFDFDF;
    border-radius: 12px;

  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #959595
  }