.testi__pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* .test.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #004459;
}

.test.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #004459;
} */

.sliderWhatWeDoPagination.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #CACACA;
}

.sliderWhatWeDoPagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #616161;
}

.sliderWhatWeDoWraper.swiper-wrapper{
    background-color: #004459;
    height: auto;
}